import React from 'react'
import { Helmet } from 'react-helmet'

const VideoSchema = ({schema = []}) => {
  return (
    <Helmet>
      {schema && (
        <script type="application/ld+json">{JSON.stringify(schema)}</script>
      )}
    </Helmet>
  )
}

export default VideoSchema