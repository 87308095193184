import React, { useEffect, useContext, useState } from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import Image from 'gatsby-image'
import { SEO, Social, BreadCrumbs, Space, Price } from '../components/shared'
import { StoreContext } from '../contexts'
import VideoSchema from '../components/shared/VideoSchema'
import {
    ProductHero,
    ProductReviews,
    ProductReviewsV2,
    ProductInformation,
    ProductTipSection,
    ProductShippingSection,
    ProductRecommended,
} from '../components/product'

import { desktopVw, mobileVw, desktopBreakpoint } from '../styles'
import { Testimonial } from '../components/shared/Testimonial'
import { useLocale, useProductViewTracking, useShopifyProduct } from '../hooks'
import { useMaiilleSchemaForLD } from '../hooks/index'

const Product = ({
    data: { contentfulProduct, contentfulGlobalSite },
    location,
    pageContext: { shopenful },
}) => {
  const {
    seoTitle,
    seoDescription,
    slug,
    title,
    description,
    tasteNotes,
    promotionTitle,
    promotionDescription,
    shopifyProduct,
    nutitionTable,
    recipes,
    id,
    sizeWithShopifyProduct,
    personalisedProductSizeAndColor,
    productBreadcrumbs,
    colorVariant,
    pdpSelectSizecolortype,
    thumbnailImage,
    relatedProducts
  } = contentfulProduct
  const {
    productPageFooterDecoration,
    productPageShippingDecoration,
    productPageShippingTitle,
    productPageShippingText,
    productPageTipDecoration,
    classicProductPageHeroBlock,
    classicProductPageTestimonialDecoration,
    productPageRetourText,
    defaultVideoTestimonial,
    facebookLink,
    instagramLink,
    personalisationLink,
  } = contentfulGlobalSite

    const [normalShopifyProduct, setNormalShopifyProduct] = useState(null)
    const [sku, setSku] = useState(null)
    const [variantId, setVariantId] = useState(null)
    const [variant, setVariant] = useState(null)
    const { client } = useContext(StoreContext)

    const locale = useLocale()

    useEffect(() => {
        if (
            shopifyProduct &&
            !sizeWithShopifyProduct &&
            !personalisedProductSizeAndColor
        ) {
            client.product.fetchByHandle(shopifyProduct.handle).then((product) => {
                setNormalShopifyProduct(product)})
        }
    }, [client, shopifyProduct])

    
    useEffect(() =>{
        const variants =
            shopenful &&
            shopifyProduct &&
            shopenful.find(
            (productItem) => productItem.handle === shopifyProduct.handle
            )
         console.log('slug', variants);
         const vari = variants && variants.variants[0] || null
         setVariant(vari)
         let prodId = variants && variants?.id?.split('/')
         setSku(() => prodId && prodId[prodId.length -1])
         setVariantId(vari && vari.id.split('/').at(-1))
    }, [])
   // console.log('sku', sku, variantId);
        

    // const getAdminIdFromStorefrontId = (storefrontId) =>
    //   typeof window !== 'undefined' &&
    //   window.atob(storefrontId).match(/\d/g).join('')

    // const decodeId = (id) =>
    //     typeof window === 'undefined'
    //         ? Buffer.from(id, 'base64').toString()
    //         : atob(id)
    // const productId = variants
    //     ? decodeId(variants.id.replace('Shopify__Product__', '')).split('/').pop()
    //     : 0
    function reviewButton() {
        if (location && location.state && location.state.values) {
            setTimeout(() => {
                var reviewBox = document.getElementsByClassName(
                    'spr-summary-actions-newreview',
                )
                reviewBox.item(0).click()
            }, 2000)
        } else {
            return null
        }
    }
    // useEffect(() => {
    //     const script = document.createElement('script')
    //     script.id = 'reviews-script'
    //     script.src =
    //         'https://productreviews.shopifycdn.com//assets//v4//spr.js?shop=maille-fr.myshopify.com'
    //     script.async = true
    //     if (locale === 'fr') {
    //         setTimeout(() => {
    //             document.body.appendChild(script)
    //             script.onload = function () {
    //                 reviewButton()
    //             }
    //         }, 1000)
    //         return () => {
    //             document.body.removeChild(script)
    //         }
    //     }
    // }, [productId])

    useEffect(() => {
        if (shopifyProduct && !personalisedProductSizeAndColor) {
            client.product.fetchByHandle(shopifyProduct.handle).then((product) => {
                setNormalShopifyProduct(product)})
        }
    }, [client, shopifyProduct])
    return (
        <ProductPageWrapper>
            <SEO
                title={seoTitle}
                location={location}
                description={seoDescription}
                pageType='Product'
                schemas={[
                    useMaiilleSchemaForLD('product', {
                        productName: seoTitle,
                        sku: sku,
                        price: variant?.price?.amount,
                        image: thumbnailImage &&
                        thumbnailImage.fluid &&
                        thumbnailImage.fluid.src,
                        description: seoTitle,
                        brand: 'Maille',
                        url: slug,
            //type: ctConstants && ctConstants.productInfo,
                    }),
                    useMaiilleSchemaForLD('website', {
                      siteName: 'Maille',
                      url: location.host,
                      sameAs: [],
                    }),
                  ]}
            />
            <VideoSchema
                schema={defaultVideoTestimonial && !shopifyProduct && {
                    "@context": "http://schema.org/",
                    "@type": "VideoObject",
                    'name': seoTitle && seoTitle,
                    'description': seoDescription && seoDescription,
                    "uploadDate": "2020-07-19",
                    "thumbnailUrl": defaultVideoTestimonial.poster.fluid.src,
                    "contentUrl": defaultVideoTestimonial.videoMp4.file.url
                }}
            />
            {/* {personalisedProductSizeAndColor && console.log("personalisedProductSizeAndColor", personalisedProductSizeAndColor)}
      {sizeWithShopifyProduct && console.log("sizeWithShopifyProduct", sizeWithShopifyProduct)} */}
            {/* {
        sizeWithShopifyProduct && sizeWithShopifyProduct.map((obj) => {
          console.log(obj.sizeShopifyProduct.variants)
        })
      } */}
            <ProductHero
                data={contentfulProduct}
                decoration={classicProductPageHeroBlock}
                title={promotionTitle}
                description={promotionDescription}
                //ratingId={productId}
                personalisationLink={personalisationLink}
                normalShopifyProduct={normalShopifyProduct}
                productBreadcrumbs={productBreadcrumbs}
                colorVariant={colorVariant ? true : false}
                pdpSelectSizeColorType={pdpSelectSizecolortype}
                sku = {variantId}
            />
            {relatedProducts && <ProductRecommended shopenful={shopenful} productId={id} relatedProducts = {relatedProducts}/>}
            <ProductContent>
                {shopifyProduct && locale === 'fr' && (
                    <ProductTipSection
                        title={promotionTitle}
                        description={promotionDescription}
                        decoration={productPageTipDecoration}
                    />
                )}
                <ProductInformation
                    recipes={recipes}
                    description={description}
                    tasteNotes={tasteNotes}
                    retours={productPageRetourText}
                    nutritionTable={nutitionTable}
                    shopifyProduct={shopifyProduct}
                />
                {shopifyProduct && (
                    <ProductShippingSection
                        title={productPageShippingTitle}
                        text={productPageShippingText}
                        decoration={productPageShippingDecoration}
                    />
                )}
                {locale === 'fr' && (
                    <>
                        <StyledSocial
                            social={{
                                title: 'Partagez',
                                share: ['Instagram', 'Facebook'],
                                links: [`${instagramLink}`, `${facebookLink}`],
                            }}
                        />
                        {!shopifyProduct && (
                            <div>
                                <Testimonial
                                    defaultImage={defaultVideoTestimonial}
                                    decoration={classicProductPageTestimonialDecoration}
                                    classicalVersion
                                />
                            </div>
                        )}
                    </>
                )}

                {locale === 'fr' && (
                    <div id='review-section'>
                        <Space l={2} s={1} />
                        {/* <ProductReviewsV2 productId={productId} location={location} /> */}
                    </div>
                )}
                 {
                    locale === 'fr' && variant && 
                        <div className="skeepers_product__reviews"
                        data-product-id={variantId}
                        data-locale="fr_FR"
                        data-price={variant.price.amount}
                        data-currency="EUR"
                        data-name={title}
                        data-url={slug}
                        data-image-url={slug}
                        data-priceValidUntil="2025"
                        data-description= {seoDescription}
                        data-brand="Maille"
                        >
                        </div>
                    //  <div className="skeepers_product__reviews"
                    //     data-product-id="6652350038129"
                    //     data-locale="fr_FR"
                    //     data-price="43.3"
                    //     data-currency="EUR"
                    //     data-name="Spécialité à la moutarde au miel et Vinaigre balsamique de Modène, 225 g"
                    //     data-url="moutarde-au-parmesan-et-aux-brisures-de-truffe-blanche-dalba-125ge"
                    //     data-image-url="The product image url">
                    // </div>
                }

                {/* <ProductReviews /> */}
                <Space l={2} s={0} />
                {shopifyProduct && (
                    <StyledFruitBanner
                        fluid={productPageFooterDecoration.image.fluid}
                        alt={productPageFooterDecoration.image.title}
                    />
                )}
            </ProductContent>
        </ProductPageWrapper>
    )
}

const ProductPageWrapper = styled.div`
  overflow: hidden;
`

const ProductContent = styled.div``

const StyledSocial = styled(Social)`
  margin: 0 0 0 ${mobileVw(16)};

  @media (min-width: ${desktopBreakpoint}) {
    padding: 0;
    margin: 0 0 0 ${desktopVw(128)};
  }
`
const StyledFruitBanner = styled(Image)`
  width: 100%;
  height: auto;
  display: block;
  margin: 0 auto 0 0;

  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVw(740)};
    margin: ${desktopVw(-60)} 0 0 0;
  }

  img {
    image-rendering: pixelated;
  }
`

export const ProductQuery = graphql`
  query ProductPage($id: String!, $node_locale: String!) {
    contentfulProduct(id: { eq: $id }) {
      id
      colorVariant
      description {
        raw
        references {
            ...richtextReferences
        }
      }
      productBreadcrumbs {
        title
        link {
          ...linkTarget
        }
      }
      nutitionTable { 
        per100
        per100Fat
        per100Salt
        per100Energy
        per100Protein
        per100Carbohydrates
        per100Fiber
        per100OfWhichSaturated
        per100OfWhichSugars
        perPortionFat
        perPortionSalt
        perPortionEnergy
        perPortionProtein
        perPortionOfWhichSaturated
        perPortionCarbohydrates
        perPortionFiber
        perPortionOfWhichSugars
        percentagePerPortionFat
        percentagePerPortionSalt
        percentagePerPortionEnergy
        percentagePerPortionProtein
        percentagePerPortionCarbohydrates
        percentagePerPortionOfWhichSaturated
        percentagePerPortionOfWhichSugars
        details {
            raw
        }
        ingredients {
            raw
            references {
                ...richtextReferences
            }
        }
      }
      relatedProducts {
        products {
            slug
            images {
              title
              fluid(sizes: "(max-width: 1023px) 20vw, 20vw") {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
            title

            thumbnailImage {
              fluid(sizes: "(max-width: 1023px) 20vw, 20vw") {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
            thumbnailLabel
            shopifyProduct {
              id
              handle
              variants {
                edges {
                  node {
                    id
                    title
                    price{
                        amount
                    }
                    availableForSale
                  }
                }
              }
            }
          }
      }
      recipeDownload {
        name
        cta {
          title
        }
        image{
            fluid(maxWidth: 1800) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            title
        }
        description {
          raw
        }
        tncDescription {
          raw
        }
        thankyouMessage{
            raw
        }
        checkboxText {
          raw
        }
        ctaPopUp {
          title
          pdfDownloads {
            title
            file {
              url
            }
          }
        }
      }
      recipes {
        ... on ContentfulPageRecipe {
          name
          slug
          title
          noPage
          images {
            fluid(maxWidth: 1800) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            title
          }
          thumbnailImage {
            fluid(maxWidth: 1800) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            title
          }
        }
      }
      title
      seoTitle
      seoDescription
      imageSizeContain
      allergyLabels
      engraveFontColor
      promotion
      thumbnailLabel
      pdpSelectSizecolortype
      images {
        title
        description
        file {
          url
        }
        fluid(maxWidth: 1800) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      videoThumbnailImage {
        fluid(maxWidth: 1800) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      shopifyProduct {
        id
        handle
        variants {
          edges {
            node {
              id
              title
              price{
                amount
              }
            }
          }
        }
      }
      productTags
      promotionTitle
      promotionDescription

      tasteNotes
      thumbnailImage {
        fluid(maxWidth: 1800) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
        title
      }
      isGourmetAndClassical
      slug
      # new query starts with

      currentSize
      appronPersonalisationOption
      sizeWithShopifyProduct {
        id
        title
        size
        slug
        sizeIndex
        sizeShopifyProduct {
          handle
          variants {
            edges {
              node {
                id
                title
                price{
                    amount
                }
                availableForSale
              }
            }
          }
        }
      }
      personalisedProductSizeAndColor {
        id
        name
        productColorVariants {
          id
          name
          colorHexCode
          colorName
          engravingHexCode
          productImageMobile {
            title
            fluid(sizes: "(max-width: 1023px) 90vw, 90vw") {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          productImageDesktop {
            title
            fluid(sizes: "(max-width: 1023px) 30vw, 30vw") {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          sizeAndShopifyEntry {
            ... on ContentfulSizeCollection {
              id
              size
              sizeIndex
              sizeShopifyProduct {
                handle
                variants {
                  edges {
                    node {
                      id
                      title
                      price{
                        amount
                      }
                      availableForSale
                    }
                  }
                }
              }
            }
          }
        }
        jarPersonalisationPrice
      }
      # new query end

      # personalisedProduct {
      #   shopifyProduct {
      #     id
      #     handle
      #     variants {
      #       edges {
      #         node {
      #           id
      #           title
      #           price
      #         }
      #       }
      #     }
      #   }
      #   productColorVariants {
      #     colorHexCode
      #     colorName
      #     engravingHexCode
      #     productImageMobile {
      #       title
      #       fluid(sizes: "(max-width: 1023px) 90vw, 90vw") {
      #         ...GatsbyContentfulFluid_withWebp_noBase64
      #       }
      #     }
      #     productImageDesktop {
      #       title
      #       fluid(sizes: "(max-width: 1023px) 30vw, 30vw") {
      #         ...GatsbyContentfulFluid_withWebp_noBase64
      #       }
      #     }
      #   }
      # }
    }
    contentfulGlobalSite(node_locale: { eq: $node_locale }) {
      node_locale
      productPageShippingTitle
      productPageShippingText
      facebookLink
      instagramLink
      defaultVideoTestimonial {
        name
        poster {
          fluid {
            src
          }
        }
        videoMov {
          file {
            url
          }
        }
        videoMp4 {
          file {
            url
          }
        }
        videoTrackFile {
          file {
            fileName
            url
          }
        }
      }
      productPageShippingDecoration {
        image {
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          title
        }
        positionDesktop
        positionMobile
        widthDesktop
        transformBlock
        widthMobile
        view
        name
      }
      classicProductPageHeroBlock {
        image {
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          title
        }
        positionDesktop
        positionMobile
        widthDesktop
        transformBlock
        widthMobile
        view
        name
      }
      classicProductPageTestimonialDecoration {
        image {
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          title
        }
        positionDesktop
        positionMobile
        widthDesktop
        transformBlock
        widthMobile
        view
        name
      }
      productPageTipDecoration {
        image {
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          title
        }
        positionDesktop
        positionMobile
        widthDesktop
        transformBlock
      }
      productPageRetourText {
        raw
      }
      productPageFooterDecoration {
        image {
          title
          fluid {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
      personalisationLink {
        ... on ContentfulComponentLink {
          link {
            ... on ContentfulPageContact {
              slug
            }
          }
        }
      }
    }
  }
`

export default Product
